.not-login{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.tracking-detail{
    width: 100%;
    min-height: 700px;
    height: 100%;
}
.head-order{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    font-size: 24px;
}
.order-tracking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 2rem;
}
.show-order{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 12px 0;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
    border-radius: 0.125rem;
    background-color: #F5F5F5;
}
.express-number{
    display: flex;
    flex-direction: column;
    padding: 0;
}
.order-detail{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    width: 80%;
    border-bottom: 2px solid #fd1081;
}
.total-price{
    display: flex;
    justify-content: end;
    width: 100%;
}
.none-border-bottom{
    border: none;
}