.products {
  margin: 20px;
  padding: 0;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.title {
  margin-top: 25px;
}

.product_single {
  /* max-height: 250px; */
  height: 350px;
  width: 350px;
  /* padding: 20px; */
  /* background-color: lightgray; */
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  text-align: left;
  /* overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
}

.product_single > img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  margin-bottom: 3px;
}

.page_title {
  text-align: center;
  background-image: url("../../../assets/img/content/title_background.png");
  height: 200px;
  padding: 75px;
}

.page_title_text {
  font-size: 32px;
  color: #fd1081;
  text-align: left;
}

@media (max-width: 476px) {
  .page_title_text {
    font-size: 28px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 3%;
  margin: 5%;
}

.pagination > span {
  border: 1px solid lightgray;
  padding: 3%;
  cursor: pointer;
}

.pagination__selected {
  background-color: lightgray;
}

.content_title {
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.content_img {
  border-radius: 15px;
  max-height: 200px;
}

.App {
  margin-bottom: 50px;
  margin-top: -31px;
}

.content_summary {
  margin-top: 5px;
  height: 100px;
  overflow: auto;
}

.button {
  padding: 16px;
}

figure img {
  /* width: 100% !important; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media  screen and (max-width: 480px) {
  .App {
    margin-top: -39px;
  }
}