@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.hide-scrollbar {
  /* margin-top: 120px; */
  /* overflow-y: hidden; */
}

.MuiTypography-root {
  font-family: "Noto Sans Thai", sans-serif !important;
}
.MuiTab-root {
  font-family: "Noto Sans Thai", sans-serif !important;
}
.MuiOutlinedInput-input {
  font-family: "Noto Sans Thai", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.rules-bottom {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.li {
  display: inline;
  padding-left: 20px;
}

a {
  cursor: pointer !important;
}
