@media only screen and (max-width: 576px) {
    .product-category {
        margin-left: 8px !important;
        margin-right: 8px !important;

    }

    .box-category {
        padding-left: 32px !important;
        padding-right: 16px !important;
    }


}

@media only screen and (max-width: 576px) {
    .product-category {
        margin-left: 8px !important;
        margin-right: 8px !important;

    }

    .box-category {
        padding-left: 32px !important;
        padding-right: 16px !important;
    }


}



.shop-category {
    margin-top: 150px;
}


.image-background-head {
    /* Add styles to your image here */
    width: 100%;
    height: 200px;
    max-width: 100%;
    max-height: 100%;
    background: url('../../../assets/img/pynk/shop/pynk_Logo_Direction.png');
    align-items: center;
    display: flex;
    padding-left: 32px;
    padding-right: 32px;

    /* Set the background image */
}

.category-name {
    font-family: MediumPynk;
    color: var(--normal-pink);
    font-size: 32px;
}

.box-nav-category {
    margin-top: 64px;
    margin-bottom: 32px;
}

.box-category {
    width: 100%;
    padding-left: 64px;
    padding-right: 32px;
    padding-bottom: 120px;
}

.product-center {
    /* text-align: center; */
}

.product-center2 {
    display: block !important;
    text-align: center;
}

.product-left {
    display: contents;
    text-align: left;

}



.product-category {
    margin-left: 16px;
    margin-right: 16px;
    display: inline-flex;
    margin-bottom: 32px;
    height: 100%;
}



.text-category-number {
    font-size: 12px !important;
    font-family: SemiBoldPynk;
    color: var(--main-text);
}

.text-category-select {
    font-size: 16px !important;
    font-family: SemiBoldPynk;
    color: var(--main-text);
}

.span-img-category {
    margin-top: -10px;
    margin-left: 0px;
    margin-right: 8px;
}

.pagination button {
    background-color: white;
    color: var(--normal-pink);
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: SemiBoldPynk;
    font-size: 18px
}

.active-pagination {
    background-color: var(--normal-pink) !important;
    color: white !important;
    width: 32px;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 100px !important;
}

.page-item {
    margin-top: 15px !important;
    color: var(--normal-pink);
    font-size: 18px;
    margin-left: 8px;
    margin-right: 8px;
    font-family: SemiBoldPynk;
}

.chevron-left {
    margin-right: 8px;
    margin-top: 15px;
    width: 24px !important;
    height: 24px !important;
}

.chevron-right {
    margin-left: 8px;
    margin-top: 15px;
    width: 24px;
    height: 24px;
}

.box-pagination {
    display: flex;
    justify-content: center;
}

.pagination {
    margin-top: 15px !important;
    color: var(--normal-pink);
    font-size: 18px;
    margin-left: 8px;
    margin-right: 8px;
    font-family: SemiBoldPynk;
    border-radius: 100px !important;

}

.pagination li {
    background-color: white;
    padding: 0px 16px 0px 16px;
    border: none !important;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 10px;
}

.white-space-ellipsis {
    padding-left: 8px;
    padding-right: 8px;

    overflow: hidden;
    /* ข้อความที่เกินจะถูกซ่อน */
    text-overflow: ellipsis;
    /* เพิ่ม ellipsis (...) หากข้อความเกิน */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* จำกัดให้แสดงได้แค่ 2 บรรทัด */
    -webkit-box-orient: vertical;

}