.button_pink {
  background-color: #ef60a3;
  color: white;
  border-radius: 50px;
  font-weight: 900;
}

.button_grey {
  background-color: #dddddd;
  color: white;
  border-radius: 50px;
  font-weight: 900;
}

.button_grey_1 {
  background-color: #dddddd;
  color: white;
  border-radius: 50px;
  font-weight: 900;
}

.button_pink_outline {
  background-color: #ffffff;
  color: #ef60a3;
  border-radius: 50px;
  font-weight: 900;
  border: 1px solid #ef60a3;
}

.previous-btn-pay {
  width: 50px;
  height: 50px;
  background-color: var(--white);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -10px;
  margin-top: 100px;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 996px) {
    left: 0px;
  }
  @media (max-width: 476px) {
    display: none;
  }
}

.next-btn-pay {
  width: 50px;
  height: 50px;
  background-color: var(--white);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -50px;
  top: 0;
  margin-top: 100px;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 996px) {
    right: -30px;
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.progress_start {
  height: 142px;
  width: 7px;
  background: #dddddd;
  border-radius: 42px;

  @media (max-width: 1200px) {
    height: 250px;
  }
}

.circle_start {
  width: 35px;
  height: 35px;
  background: #dddddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  margin-top: 16px;
}

.header_img_video {
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 153px;
  width: 100%;
  max-width: 272px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_pink {
}

.box_program {
  &:hover {
    border: 2px solid #ef60a3;
    cursor: pointer;
    .text_pink {
      color: #ef60a3;
    }
    .button_grey_1 {
      background-color: #ef60a3;
    }
  }
}

.circle_step {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


 .head-pass-img-box {
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0) 62%, rgba(255, 255, 255, 1) 100%), 
    url('../../../assets/img/pynk/shutterstock.png'); 
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center; 
  max-width: 1014px;
  width: 100%;
  max-height: 675px;
  height: 100%;
  z-index: 0;
}

.box-head-pass {
  width: 100%;
  height: 494px;
  background-color: #ef60a3 !important;
}
.head-pass-text-box {
  width: 750px;
  z-index: 1;
  margin-top: -400px;
  position: absolute;
  right: 0px;
}
.text-hade-play-pass {
  font-size: 64px;
  font-weight:bold;
  color: #7a7a7a;
}
.text-play-pass {
  margin-top: -16px;
  font-size: 48px;
  font-weight:bold;
  color: #7a7a7a;
}
.text-play {
  margin-top: -16px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}
.text-hade-play-pass  span {
  color:  #ef60a3;
}


.button_pink_pass {
  background-color: #ef60a3;
  color: #ffffff;
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #ef60a3;
  height: 44px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-top: 24px;
}



@media only screen and (max-width: 1200px) {
  .head-pass-text-box {
    position: static; 
    width: 100%; 
    padding: 16px;
    margin-top: 26px;
  }

  .text-hade-play-pass {
    font-size: 38px; 
  }

  .text-play-pass {
    font-size: 24px;
  }

  .text-play {
    font-size: 16px; 
  }

  .button_pink_pass {
    width: 150px;
    height: 40px;
    margin-top: 16px;
  }

  .big-box-pass {
    background-color: #fff8fb;
    width: 100%;
    padding: 6%  !important;
    display: flex;
    justify-content: center;
  }
  .pink_pass-cat {

    padding: 16px !important;

   }
   .pink_pass-cat-p {
    margin-left: 0 !important;
    }

    .idea-behind {
      padding: 16px !important;

      
     }

     .easy-use-box {
      min-height: 447px;
      width: 100%;
      background-color: #ffffff;
      padding: 24px 16px !important;
      display: flex;
      align-items: center;
      justify-content: center;
     }

     .advantages-box {
      padding: 16px !important;
     
     }
  

}

@media only screen and (min-width: 768px) and (max-width: 1201px) {
  .advantages-container {
    gap: 20px !important;
  }
}

.big-box-pass {
  background-color: #fff8fb;
  width: 100%;
  padding: 12% 12.2%;
  display: flex;
  justify-content: center;
}
 .pink_pass-cat {
  background-color: #ffffff;
  border-radius: 16px;
  max-width: 1196px;
  width: 100%;
  min-height: 483px;
  padding: 64px;
  font-size: 20px;
 }
 .pink_pass-cat span {
  font-weight: bold;
 }
 .pink_pass-cat-p {
 margin-left: 24px;
 }
 .popular_img {
  width: 80px;
  height: 80px;
 }
 .text-head-cat  {
  color: #4F4F4F;
  font-size: 32px;
  font-weight: 600;
 }
 .idea-behind {
  padding: 16px 13%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url('../../../assets/img/pynk/sl_0.png'); 
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center; 
  min-height: 400px;
 }
 .rectangle4424 {
  max-width: 442px;
  width: 100%;
  height: auto;
 }
 .idea-text-head {
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
 }
 .idea-text {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
 }
 .easy-use-box {
  min-height: 447px;
  width: 100%;
  background-color: #ffffff;
  padding: 46px 12%;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .mockup {
  max-width: 552px;
  width: 100%;
  height: auto;
 }
 .text-head-mockup {
  font-size: 32px;
  color: #ef60a3;
  font-weight: 600;

 }
 .text-mockup {
  list-style-position: outside; 
  padding-left: 0.5em; 
  text-indent: -0.1em; 
  line-height: 1.8; 
 }
 .text-mockup span {
  font-weight: bold;
 }
 .advantages-box {
  width: 100%;
  background-color: #fff8fb;
  min-height: 737px;
  padding: 12%;

 }
 
 .text-advantages {
  font-size: 32px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-bottom: 32px;
 }
 .advantages-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 80px;
  
}

.advantages {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
}

.advantages-image {
  max-width: 100%;
  border-radius: 10px;
}

.advantages-title {
  font-weight: bold;
  margin-top: 15px;
  font-size: 18px;
}

.advantages-text {
  margin-top: -16px;
  font-size: 16px;
  color: #333;
}


/* Media query สำหรับหน้าจอขนาดเล็ก (มือถือ) */
@media only screen and (max-width: 767px) {
  .advantages-container {
    flex-direction: column; /* เปลี่ยนจาก row เป็น column */
    align-items: center; /* จัดให้อยู่ตรงกลาง */
  }

  .advantages {
    width: 100%; /* ให้กล่องมีความกว้างเต็มหน้าจอมือถือ */
    margin-bottom: 20px; /* เพิ่มระยะห่างระหว่างกล่อง */
  }
  .pricing-container-box {
    padding: 32px 16px !important;
  }
}

.program-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* จัดให้อยู่ตรงกลางแนวแกนหลัก */
  align-items: center; /* จัดให้อยู่ตรงกลางแนวแกนรอง */
  padding: 20px 20px 48px 20px;
}

.program-text-head {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  margin-top: 64px;
  margin-bottom: 48;
  text-align: center;
}

.program {
  margin: 10px; /* เพิ่มระยะห่างระหว่างแต่ละกล่อง */
}

.program-image {
  max-width: 284px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.pricing-container-box {
  background-color: #fff8fb;
  padding: 64px;
}
.text-select-pass {
  font-size: 24px;
  color: #000000;
  font-weight: bold;
  text-align: center;
}
.text-select {
  margin-top: -8px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  text-align: center;
}
.pricing-container {
  display: flex;
  justify-content: center; /* จัดให้อยู่ตรงกลางแนวนอน */
  align-items: flex-start; /* จัดให้อยู่ด้านบนสุด */
  flex-wrap: wrap; /* ให้ย่อแถวอัตโนมัติเมื่อจอเล็กลง */
  padding: 20px;
  gap: 20px; /* ระยะห่างระหว่างกล่อง */
}

.pricing-box {
  margin-top: 64px;
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 32px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 310px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.best_seller {
  top: -20px;
  position: absolute;
  width: 105px;
  height: 36px;
  left: 50%; 
  transform: translateX(-50%);
}
.program-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.program-count {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
}
.price {
  font-size: 64px;
  font-weight: bold;
  color: #000000;
}
.price span {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: block;
}

.btn-signup {
  background-color: #EF60A3;
  width: 180px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: 16px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50px;
}
.plus-extra {
 top: -8px !important;
  position:relative;
  padding: 6px 10px;
  background-color:#ED0876;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50px;
}

.shutterstock-text-box {
  max-width: 587px;
  width: 100%;
  height: 220px;
  margin-right: 12%;
}
.shutterstock-text-1{
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}
.shutterstock-text-2{
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}
.shutterstock-text-3{
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}
.shutterstock-footer {
  background-image: url('../../../assets/img/pynk/shutterstock_2.png'); 
  background-repeat: no-repeat;
  background-size: cover; /* ให้ภาพเต็มจอ */
  background-position: center; /* จัดภาพให้อยู่ตรงกลาง */
  min-height: 603px ; /* ความสูงขั้นต่ำ */
  width: 100%;
  display: flex;
  justify-content: right; /* จัดให้อยู่กึ่งกลาง */
  align-items: center;
  background-color: #FE9FBF;
  overflow: hidden; /* ตัดสีเกินที่อยู่นอกขอบ */
}

/* ปรับให้รองรับจอมือถือ */
@media (max-width: 1200px) {
  .shutterstock-footer {
    background-image: url('../../../assets/img/pynk/shutterstock_3.png'); 
    background-size: contain;
    min-height: 603px;
    background-position: top;
    padding-bottom: 130px;
  }

  .shutterstock-text-box {
    max-width: 100%;
    padding: 24px;
    margin-top: 90%;
    margin-right: 0% !important;
  }

  .shutterstock-text-1,
  .shutterstock-text-2,
  .shutterstock-text-3 {
    font-size: 18px;
  }
}

.button-pass-box {
  background-color: #fff8fb;
  width: 100%;
  height: 172px;
  display: flex;
  justify-content: center;
  align-items: center;
}